import { forwardRef } from "react"

import { cn } from "@/utils/cn"

const TextArea = forwardRef<HTMLTextAreaElement, React.ComponentProps<"textarea">>(({ className, ...props }, ref) => {
  return (
    <textarea
      className={cn(
        "dg-flex dg-min-h-[80px] dg-w-full dg-rounded-md dg-border dg-border-slate-200 dg-bg-white dg-px-3 dg-py-2 dg-text-xs dg-text-sherpa-blue-950 dg-ring-offset-white placeholder:dg-text-gray-500 focus-visible:dg-outline-none focus-visible:dg-ring-2 focus-visible:dg-ring-slate-950 focus-visible:dg-ring-offset-2 disabled:dg-cursor-not-allowed disabled:dg-opacity-50 md:dg-text-base",
        className,
      )}
      ref={ref}
      {...props}
    />
  )
})
TextArea.displayName = "Textarea"

export { TextArea }
