"use client"

import { useState } from "react"

import { Popover, PopoverContent, PopoverTrigger } from "@/components/popover/Popover"
import { useIsMobile } from "@/hooks"

type Props = {
  trigger: React.ReactNode
  triggerAsChild?: boolean
  triggerOnlyOnClick?: boolean
  shouldChildrenStayOpenOnHover?: boolean
  children: React.ReactNode
  disabled?: boolean
  className?: string
}

export function InteractiveDescription(props: Props) {
  const {
    disabled,
    children,
    trigger,
    triggerAsChild,
    triggerOnlyOnClick,
    shouldChildrenStayOpenOnHover = true,
    className,
  } = props

  const isMobile = useIsMobile()

  const [open, setOpen] = useState(false)

  function handleMouseEnter() {
    if (disabled) {
      return
    }

    setOpen(true)
  }

  function handleMouseLeave() {
    setOpen(false)
  }

  return (
    <Popover open={disabled ? false : open} onOpenChange={setOpen}>
      <PopoverTrigger
        className="dg-cursor-help dg-bg-transparent"
        asChild={triggerAsChild}
        onMouseEnter={triggerOnlyOnClick || isMobile ? undefined : handleMouseEnter}
        onMouseLeave={triggerOnlyOnClick || isMobile ? undefined : handleMouseLeave}
      >
        {trigger}
      </PopoverTrigger>

      {children && (
        <PopoverContent
          onMouseEnter={triggerOnlyOnClick || !shouldChildrenStayOpenOnHover ? undefined : handleMouseEnter}
          onMouseLeave={triggerOnlyOnClick || !shouldChildrenStayOpenOnHover ? undefined : handleMouseLeave}
          className={className}
        >
          {children}
        </PopoverContent>
      )}
    </Popover>
  )
}
